import { DataService } from "../services/data.service"

export class Product {

  _id!: string;
  brand!: string; // Brand.brand
  countries: any = {};
  countries_group: any = {};
  currency?: string; // Currency.code
  currency_converted!: string; // Currency.code
  idBrand!: string;
  name?: string;
  nation: string = ""; // Nation.code
  price?: number;
  original_price_converted?: number;
  price_converted_cart?: number;
  price_converted_for_customer?: number;
  providerType?: 0 | 1;
  quantity?: number;
  quantity_cart?: number;
  sku?: string;
  have_discount?: boolean;

  constructor(data: any) {
    this.setAllFields(data);
  }

  setAllFields(data: any) {
    if (data) {
      this._id = data._id;
      this.brand = data.brand;
      this.countries = data.countries;
      this.countries_group = data.countries_group;
      this.currency = data.currency;
      this.currency_converted = data.currency_converted;
      this.idBrand = data.idBrand;
      this.name = data.name;
      this.nation = data.nation;
      this.price = data.price;
      this.price_converted_cart = data.price_converted_cart;
      this.price_converted_for_customer = data.price_converted_for_customer;
      this.providerType = data.providerType;
      this.quantity = data.quantity;
      this.quantity_cart = data.quantity_cart;
      this.sku = data.sku;
      this.original_price_converted = data.original_price_converted;
      this.have_discount = data.have_discount;
      DataService.globalSettings.next({
        action: "cart_products-changed",
      });
    }
  }
}



