import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input, Inject
} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../services/data.service";
import {ViewService} from "../../services/view.service";
import {
  environment
} from "../../../environments/environment";
import {
  MAT_DIALOG_DATA,
  MatDialogRef
} from "@angular/material/dialog";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-email-order',
  templateUrl: './email-order.component.html',
  styleUrls: ['./email-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailOrderComponent implements OnInit {

  formControl = ({
    email: new FormControl("", [
      Validators.required,
      Validators.email
    ])
  });
  lang: any;

  constructor(
    public dialogRef: MatDialogRef<EmailOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public inputs: any,
    private translate: TranslateService,
    private data: DataService,
    private view: ViewService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
    this.lang = this.inputs.lang;
    this.formControl.email.setValue(localStorage.getItem('order_email'));
  }

  form_getErrorMessage(fieldName: string) {
    switch (fieldName) {
      case "email":
        if (this.formControl.email.hasError('required')) {
          return this.translate.instant('EMAIL_ORDER.required_email');
        }
        return (this.formControl.email.hasError('email') ? this.translate.instant('EMAIL_ORDER.invalid_email') : '');
      default:
        console.log(`form_getErrorMessage return`);
        return;
    }
  }

  createOrder(){
    localStorage.setItem('order_email', this.formControl.email.value);
    switch (this.inputs.payment_type){
      case 1:
        this.coinbasePostOrder();
        break;
      case 2:
        this.stripePostOrder();
        break;
      case 3:
        this.opennodePostOrder();
        break;
    }
  }

  async coinbasePostOrder() {
    if (!this.data.cart) {
      console.log(`return: !data.cart`);
      return;
    }
    if (!this.data.currentUser) {
      console.log(`return: !data.currentUser`);
      return;
    }

    if (this.formControl.email.hasError('required')) {
      return
    }
    if (this.formControl.email.hasError('email')) {
      return
    }
    this.view.showLoading(this.translate.instant('EMAIL_ORDER.sending_order'), 'checkout_postOrder_loading');
    let apiSuccess;
    let res: any;
    try {
      let success_base_url = `https://${window.location.hostname}/order`;
      if(window.location.hostname.indexOf('localhost') >= 0){
        success_base_url = `http://${window.location.hostname}:${window.location.port}/order`;
      }
      res = await this.api.postOrder({
        email: this.formControl.email.value,
        user__id: this.data.currentUser._id,
        success_base_url: success_base_url,
        cancel_url: window.location.href,
        platform: environment.platform,
        cart_number: this.data.cart.cart_number,
      });
      console.log(`api.postOrder`, res);
      apiSuccess = true;
    } catch (e: any) {
      console.error(`e`, e);
      this.postOrder_errorHandler(e.error);
    }
    await this.view.hideLoading("checkout_postOrder_loading");
    if (apiSuccess) {
      window.open(res.hosted_url, "_self");
    }
  }

  async opennodePostOrder() {
    if (!this.data.cart) {
      console.log(`return: !data.cart`);
      return;
    }
    if (!this.data.currentUser) {
      console.log(`return: !data.currentUser`);
      return;
    }

    if (this.formControl.email.hasError('required')) {
      return
    }
    if (this.formControl.email.hasError('email')) {
      return
    }
    this.view.showLoading(this.translate.instant('EMAIL_ORDER.sending_order'), 'checkout_postOrder_loading');
    let apiSuccess;
    let res: any;
    try {
      let success_base_url = `https://${window.location.hostname}/order`;
      if(window.location.hostname.indexOf('localhost') >= 0){
        success_base_url = `http://${window.location.hostname}:${window.location.port}/order`;
      }
      res = await this.api.postOpennodeOrder({
        email: this.formControl.email.value,
        user__id: this.data.currentUser._id,
        success_base_url: success_base_url,
        platform: environment.platform,
        cart_number: this.data.cart.cart_number,
      });
      console.log(`api.postOrder`, res);
      apiSuccess = true;
    } catch (e: any) {
      console.error(`e`, e);
      this.postOrder_errorHandler(e.error);
    }
    await this.view.hideLoading("checkout_postOrder_loading");
    if (apiSuccess) {
      window.open(res.hosted_url, "_self");
    }
  }

  async stripePostOrder(){
    if (!this.data.cart) {
      console.log(`return: !data.cart`);
      return;
    }
    if (!this.data.currentUser) {
      console.log(`return: !data.currentUser`);
      return;
    }
    if (this.formControl.email.hasError('required')) {
      return;
    }
    if (this.formControl.email.hasError('email')) {
      return;
    }
    this.view.showLoading(this.translate.instant('EMAIL_ORDER.sending_order'), 'checkout_postOrder_loading');
    let apiSuccess;
    let res: any;
    try {
      let success_base_url = `https://${window.location.hostname}/order`;
      if(window.location.hostname.indexOf('localhost') >= 0){
        success_base_url = `http://${window.location.hostname}:${window.location.port}/order`;
      }
      res = await this.api.postStripeOrder({
        email: this.formControl.email.value,
        user__id: this.data.currentUser._id,
        cart_number: this.data.cart.cart_number,
        platform: environment.platform,
        cancel_url: window.location.href,
        success_base_url: success_base_url
      });
      if(res?.url){
        window.location.href = res.url;
      }
      apiSuccess = true;
    } catch (e: any) {
      console.error(`e`, e);
      this.postOrder_errorHandler(e);
    }
    await this.view.hideLoading("checkout_postOrder_loading");
    if (apiSuccess) {
      window.open(res.hosted_url, "_self");
    }
  }

  /**
   * more info about the error in
   * Google Drive / "DOCUMENTAZIONE API" / "API Webapp" / "POST /order"
   * https://docs.google.com/document/d/1_rvUHPIWAyv0JiRp8ayNMigAJUAvEt_Rafh0qPAZ3_Y/edit#heading=h.za7oz51zy39j
   */
  async postOrder_errorHandler(error: any) {
    let dialogMsg;
    let matDialogClose;
    switch (error.type) {
      case 3:
      case 4:
      case 5:
      case 6:
        dialogMsg = this.translate.instant('EMAIL_ORDER.problemi_carrello');
        matDialogClose = "case-0-to-6";
        break;
      case 7:
        dialogMsg = this.translate.instant('EMAIL_ORDER.products_stock_changed');
        matDialogClose = "case-7";
        break;
      case 9:
        dialogMsg = this.translate.instant('EMAIL_ORDER.problemi_sconto');
        matDialogClose = "case-9";
        break;
      default:
        console.log(`e.type default case`);
        dialogMsg = this.translate.instant('EMAIL_ORDER.generic_error');
        matDialogClose = "case-default";
    }

    this.dialogRef.close();

    const dialogResult = await this.view.alertDialog({
      afterClosed_returnResult: true,
      disableClose: true,
      lang: this.lang,
      buttons: [
        {
          color: "primary",
          lbl: this.translate.instant('EMAIL_ORDER.ok'),
          matDialogClose: matDialogClose,
        },
      ],
      msg: dialogMsg,
      noCloseBtn: true
    });
    switch (dialogResult) {
      case "case-0-to-6":
        window.location.reload();
        break;
      case "case-9":
        window.location.reload();
        break;
      case "case-7":
      case "case-8":
      case "case-default":
        this.dialogRef.close();
        break
      default:
        console.log(`dialogResult default case`);
    }
  }
}
