import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GtagModule } from 'angular-gtag';

import {forkJoin, Observable} from 'rxjs';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader, TranslateService
} from '@ngx-translate/core';

export function translateLoader(http: HttpClient) {

  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/', suffix: '.json'},
    {prefix: './assets/i18n/platform-', suffix: '.json'}
  ]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {

  constructor(
    private http: HttpClient,
    public resources: { prefix: string, suffix: string }[] = [{
      prefix: '/assets/i18n/',
      suffix: '.json'
    }]
  ) {}

  public getTranslation(lang: string): any {
    let list: Observable<any>[] = [];
    this.resources.map(config => {
      list.push(this.http.get(`${config.prefix}${lang}${config.suffix}`));
    });

    var nestedassign = (target: any, source: any) => {
      Object.keys(source).forEach(sourcekey=>{
        if (Object.keys(source).find(targetkey=>targetkey===sourcekey) !== undefined && typeof source[sourcekey] === "object") {
          target[sourcekey]=nestedassign(target[sourcekey],source[sourcekey]);
        } else {
          target[sourcekey]=source[sourcekey];
        }
      });
      return target;
    }

    return new Observable(subscriber => {
      let final;
      forkJoin(list).subscribe((response: any) => {
        final = response.reduce((a: any, b: any) => {
          // return Object.assign(a, b);
          return nestedassign(a, b);
        });
        console.log(final)
        subscriber.next(final);
      });
    });

  }
}


import { registerLocaleData } from '@angular/common';
// Aggiungere per tutte le lingue che si vogliono nei pipe come quello delle date
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);


import { ComponentsModule } from './components/components.module';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {environment} from "../environments/environment";
import {
  MatSnackBarModule
} from "@angular/material/snack-bar";


@NgModule({
  bootstrap: [
    AppComponent,

  ],
  declarations: [
    AppComponent,
    // TopbarComponent,

  ],
  exports: [

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ComponentsModule,
    MatSnackBarModule,
    // VirtualScrollerModule, // npm i ngx-virtual-scroller

    // * @angular/material
    // MaterialModule,
    // MatDialogModule,

    // * translation
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translateLoader),
        deps: [HttpClient]
      },
      isolate: false
    }),
    GtagModule.forRoot({ trackingId: environment.g_analytics.key, trackPageviews: true })
  ],
  providers: [

    // @angular/material
    {
      // https://stackoverflow.com/questions/47270324/nullinjectorerror-no-provider-for-matdialogref
      provide: MatDialogRef,
      useValue: {}
    },

    // * translation
    { provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        let tryLang: string = localStorage["appLanguage"] || (navigator.language[0] + navigator.language[1]);
        console.log(`tryLang`, tryLang)
        // Aggiungere tutte le lingue che si vogliono per i
        // pipes come quello delle date

        let avilable: string[] = ['it', 'en'];
        for(let lang of avilable){
          if(lang === tryLang) return lang;
        }
        return 'en';
      },
      deps: [TranslateService]
    },
    // { provide: LOCALE_ID, useValue: window.navigator.language},

  ],
})
export class AppModule { }
