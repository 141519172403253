export const environment = {

  production: true,
  apiUrl: "https://api.kisard.com/webApp/",
  version: "1.7",
  platform: 2, // Kisard
  appName: 'Kisard',
  g_analytics: {
    key: 'G-WQFYP6JPFV',
    event_pageLoad: 'kisard_shop_pageLoad',
    event_purchase: 'kisard_shop_purchase'
  }
};
