import { Injectable } from '@angular/core';
import { firebaseRealtimeDb } from '../app.component';
import { ref as fbDbRef, child, get as fbDbGet } from "firebase/database";
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ViewService, ViewService as View } from 'src/app/services/view.service';
import { getListItem } from '../misc/util';
import { ApiService } from './api.service';
import { Utility } from '../models/utility';
import { DialogConfirmComponent, DialogConfirmData } from '../components/dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { Nation } from '../models/nation';
import { Product } from '../models/product';
import { Brand } from '../models/brand';
import { User } from '../models/user';
import { Cart } from '../models/cart';
import { Currency } from '../models/currency';
import {environment} from "../../environments/environment";

// don't export these types from here
// but from their own .ts file in: models/
// eg. models/nation.ts

export type TglobalSettings_subject = {
  action:
    /*
    reminder: a product can be added, removed or updated;
    the reminder above may be useful to understand where to find/put
    the action just below
    */
    | "cart_products-changed"
    | "nation-changed"
    | "currency-changed"
    | "language-changed"
    | "all-country-settings"
}

// * method types

type get_general_parameter_opts = {
  throwToCaller?: boolean
}

type getProduct_opts = {
  brand_id: string
  getQuantity?: boolean
  noLoading?: boolean
  noReject?: boolean
}

type getUser_opts = {
  appIsStarting?: boolean
  currency?: string
  language?: string
  nation?: string
  noLoading?: boolean
  throwToCaller?: boolean
  withReturn?: boolean
}

type postCart_opts = {
  nation_code?: string
  product__id: string
  quantity: number
  throwToCaller?: boolean
}

type selectCurrency_opts = {
  currency_code?: string
  noApi?: boolean
  noGlobalSettings_event?: boolean
}

type selectLanguage_opts = {
  langKey?: string,
  noApi?: boolean
  noGlobalSettings_event?: boolean
}


@Injectable({
  providedIn: 'root'
})
export class DataService {

  // * brand

  brands: Brand[] = []
  brandsMap: Map<string, Brand> = new Map()
  selectedBrand?: Brand | null

  // * cart

  /*
  I didn't want the cart or its properties to be too nested so I decided to
  extract it from currentUser
  for assignment see: set_currentUserAndSubfields()
  */
  cart?: Cart // current cart
  cartIsFresh?: boolean

  // ? for cartProducts see section "* products"

  // * currency

  currencies: Currency[] = []
  currenciesMap: Map<string, Currency> = new Map()

  selectedCurrency?: Currency

  // * general parameter

  readonly FILTER_KEY_ALL = "_all"/*  as const */
  general_parameter: any
  categories: Map<string, any> = new Map()

  // * language

  // ? motive: I don't feel safe using this.translate.currentLang
  currentLang?: string
  currentLangName?: string;

  languages: any = {}

  // * listener

  /* listener: any = {
    globalSettings: null,
  } */

  static globalSettings = new Subject<TglobalSettings_subject>()
  static globalSettings$ = DataService.globalSettings.asObservable()

  // * nation

  nations: Map<string, Nation> = new Map()
  selectedNation?: Nation

  // * navigation

  // to pass data between components
  // navParam: any

  // * ready

  static ready = new Subject<any>();
  static ready$ = DataService.ready.asObservable();
  static dataReady: boolean;

  // * product`

  // products: Product[] = []
  cartProducts: Product[] = []

  // * test

  cartItems = [
    {
      // img: "",
      title: "TransCash Coupon 20 €",
      line1: {
        lbl: "Instantanea via email",
        icon: "message",
      },
      line2: {
        lbl: "Riscattabile in tutto il mondo",
        icon: "public",
      },
      price: "20,00",
      // inStock: true,
    },

    {
      img: "assets/img/_trail-5yOnGsKUNGw-unsplash.jpg",
      title: "item 2 title",
      line1: {
        lbl: "Uenfkwrf giergnerig gerin genri",
        icon: "public_off",
      },
      // line2: {
      //   lbl: "",
      //   icon: "",
      // },
      price: "50,00",
      inStock: true,
    },

    {
      img: "https://www.bitrefill.com/content/cn/v1553602580/ikea.svg",
      // title: "",
      line1: {
        lbl: "nrwn fwrnignwn gnwin gniwrni gnwi ngnrwnigrin",
        icon: "language",
      },
      line2: {
        lbl: "NRWGIREGJERIGERJGIEGERINR",
        // lbl: "NRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERINRWGIREGJERIGERJGIEGERI",
        icon: "",
      },
      line3: {
        lbl: "venfrn ieriggnr",
        icon: "style",
      },
      price: "100,00",
      inStock: false,
    },

    {
      img: "assets/img/_christian-wiediger-ZiAwa7FV3OQ-unsplash.jpg",
      // title: "",
      // line1: {
      //   lbl: "",
      //   icon: "",
      // },
      // line2: {
      //   lbl: "",
      //   icon: "",
      // },
      price: "200,00",
      inStock: true,
    },

  ]

  // * translation

  // lang: any

  // * user

  currentUser?: User

  platform: number = environment.platform;
  appName: string = environment.appName;

  promo_code?: string;

  constructor(
    private translate: TranslateService,
    private api: ApiService,
    private view: ViewService,
    public dialog: MatDialog,
  ) {
    // this.listenerGlobalOptions()
  }

  /**
   * calling this method in the constructor of this class
   * may give a non-populated this.lang
   * since services load very early on, too early for the
   * translation module to be ready (ie. w/ a populated json)
   */
  async initDataService() {
    // * translation
    this.translate.get([
      "APP_INIT",
      "DATA_SERVICE",
      "GLOBAL_MISC",
    ]).subscribe(async (lang: any) => {
      // console.log(`lang`, lang)
      // this.lang = lang

      // let loading

      // /* loading =  */this.view.showLoading(lang.APP_INIT.initializing_app)
      // /* loading =  */this.view.hideLoading()
      this.view.showLoading(lang.APP_INIT.initializing_app)

      try {
        // await this.get_general_parameterFirebase({
        await this.get_general_parameter({
          throwToCaller: true,
        })
      } catch (e) {
        console.error(`e`, e)
        this.view.hideLoading()
        this.promptForAppReload(lang)
        return
      }

      // getUser & getBrands can be executed in parallel

      try {
        await this.getBrands()
      } catch (e) {
        console.error(`e`, e)
        this.view.hideLoading()
        this.promptForAppReload(lang)
        return
      }

      try {
        /* const res: any =  */await this.getUser(
          // localStorage["currentUser._id"],
          {
            // appIsStarting: true,
            noLoading: true,
            throwToCaller: true,
            // withReturn: true,
          }
        )
        // this.currentUser__id = res._id
        // if (!localStorage["currentUser._id"]) {
        //   localStorage.setItem("currentUser._id", res._id)
        // }
        if (this.currentUser) {
          if (!localStorage["currentUser._id"]) {
            localStorage.setItem("currentUser._id", this.currentUser._id)
          }
          this.selectCurrency({
            currency_code: this.currentUser.currency,
            noApi: true,
            noGlobalSettings_event: true,
          })
          this.selectLanguage({
            langKey: this.currentUser.language,
            noApi: true,
            noGlobalSettings_event: true,
          })
          this.selectNation(this.currentUser.nation, true)
        } else {
          console.log(`initDataService() !currentUser`, this.currentUser)
        }
      } catch (e) {
        console.error(`e`, e)
        this.view.hideLoading()
        this.promptForAppReload(lang)
        return
      }

      DataService.ready.next(null)
      this.view.hideLoading()
    });
  }

  // Dice se è terminato il caricamento dei dati iniziali
  static isReady() {
    return new Promise((resolve) => {
      if (DataService.dataReady) {
        return resolve(null)
      }
      const listener = DataService.ready$.subscribe(() => {
        // console.log(`isReady launched, now unsubscribing`, )
        DataService.dataReady = true
        listener.unsubscribe()
        resolve(null)
      })
    })
  }

  // * api

  async get_general_parameter(opts: get_general_parameter_opts) {
    const {
      throwToCaller,
    } = opts || {}

    let apiError

    try {
      const res = await this.api.get_general_parameter(this.platform)
      console.log(`get_general_parameter() res`, res)

      this.general_parameter = res

      // * category

      // https://stackoverflow.com/questions/36644438/how-to-convert-a-plain-object-into-an-es6-map
      this.categories = new Map([
        [
          this.FILTER_KEY_ALL,
          {
            // nameTranslatable: "GLOBAL_NATION.all",
          },
        ],
        // mi stava dando problemi usare .unshift()
        ...Object.entries(this.general_parameter.category),
      ])

      // * currency

      // this.currenciesMap = new Map(
      //   Object.entries(this.general_parameter.currency)
      // )
      // this.currencies = Object.values(this.general_parameter.currency)
      for (const key in this.general_parameter.currency) {
        this.currenciesMap.set(key, this.general_parameter.currency[key])
        this.currencies.push(
          this.currenciesMap.get(key)!
        )
      }
      // console.log(`currencies`, this.currencies)
      // console.log(`currenciesMap`, this.currenciesMap)

      // * language

      this.languages = this.general_parameter.language

      // * nation

      this.nations = <Map<string, Nation>> new Map([
        [
          this.FILTER_KEY_ALL,
          {
            code: this.FILTER_KEY_ALL,
            nameTranslatable: `GLOBAL_NATION.${this.FILTER_KEY_ALL}`,
          },
        ],
        // mi stava dando problemi usare .unshift()
        ...Object.entries(this.general_parameter.nation),
      ])

      View.updateView.next(null)

    } catch (e) {
      console.error(`e`, e)
      apiError = e
    }

    if (apiError) {
      if (throwToCaller) {
        throw apiError
      } else {
        // TODO error alert
      }
    }

  }

  /**
   * firebase version
   * @param opts
   */
  async get_general_parameterFirebase(opts: get_general_parameter_opts) {
    const {
      throwToCaller,
    } = opts || {}

    // read data once
    // const dbRef = fbDbRef(getDatabase());
    const dbRef = fbDbRef(firebaseRealtimeDb)

    let apiError

    await fbDbGet(child(dbRef, `general_parameter`)).then((snapshot) => {
      if (snapshot.exists()) {
        // console.log(snapshot.val())
        this.general_parameter = snapshot.val()

        // * category

        // https://stackoverflow.com/questions/36644438/how-to-convert-a-plain-object-into-an-es6-map
        this.categories = new Map([
          [
            this.FILTER_KEY_ALL,
            {
              // nameTranslatable: "GLOBAL_NATION.all",
            },
          ],
          // mi stava dando problemi usare .unshift()
          // (...inoltre in questo momento dovrei essere sul sito statico e non a qui mettere a posto bug)
          ...Object.entries(this.general_parameter.category),
        ])

        // * currency

        // this.currenciesMap = new Map(
        //   Object.entries(this.general_parameter.currency)
        // )
        // this.currencies = Object.values(this.general_parameter.currency)
        for (const key in this.general_parameter.currency) {
          this.currenciesMap.set(key, this.general_parameter.currency[key])
          this.currencies.push(
            this.currenciesMap.get(key)!
          )
        }
        // console.log(`currencies`, this.currencies)
        // console.log(`currenciesMap`, this.currenciesMap)

        // ? test on js ref
        // #region
        /* const foundInArray = getListItem({
          key: "code",
          list: this.currencies,
          value: "AED",
        })
        console.log(`foundInArray`, foundInArray)

        const foundInMap = getListItem({
          key: "AED",
          list: this.currenciesMap,
        })
        console.log(`foundInMap`, foundInMap)

        foundInArray.name = null
        // foundInMap.name = null
        console.log(`foundInArray changed?`, JSON.parse(JSON.stringify(foundInArray)))
        console.log(`foundInMap changed?`, JSON.parse(JSON.stringify(foundInMap)))
        */
        // #endregion

        // * language

        this.languages = this.general_parameter.language

        // * nation

        this.nations = <Map<string, Nation>> new Map([
          [
            this.FILTER_KEY_ALL,
            {
              code: this.FILTER_KEY_ALL,
              nameTranslatable: `GLOBAL_NATION.${this.FILTER_KEY_ALL}`,
            },
          ],
          // mi stava dando problemi usare .unshift()
          // (...inoltre in questo momento dovrei essere sul sito statico e non a qui mettere a posto bug)
          ...Object.entries(this.general_parameter.nation),
        ])

        View.updateView.next(null)
      } else {
        console.log("No data available");
      }
    }).catch((e) => {
      console.error(`e`, e)
      apiError = e
    })

    if (apiError) {
      if (throwToCaller) {
        throw apiError
      } else {
        // TODO error alert
      }
    }

  }

  // * brand

  async getBrands() {
    try {
      const res: any = await this.api.getBrands()
      console.log(`getBrands() res`, res)
      res.data.forEach((el: any) => {
        this.brandsMap.set(el._id, new Brand(el))
        this.brands.push(
          this.brandsMap.get(el._id)!
        )
      });
      View.updateView.next(null);
    } catch (e) {
      console.error(`e`, e)
    }
  }

  selectBrand(brand_id: string) {
    this.selectedBrand = getListItem({
      key: "_id",
      list: this.brands,
      value: brand_id,
    })
    console.log(`selectedBrand`, this.selectedBrand);

    View.updateView.next(null)
  }

  // * cart (move under "user"?)

  async postCart(opts: postCart_opts) {
    console.log(`postCart()`, )

    const {
      throwToCaller,
      // api parameters
      nation_code,
      product__id,
      quantity,
    } = opts || {}

    // checking !this.currentUser would also be fine really...
    if (!this.currentUser?._id) {
      console.log(`return: !currentUser?._id`, )
      return
    }
    if (!this.selectedNation) {
      console.log(`return: !selectedNation`, )
      return
    }

    this.view.showLoading(
      this.translate.instant('DATA_SERVICE.saving_cart_data'),
      "dataService_postCart_loading"
    )

    let apiError

    try {
      const res = await this.api.postCart({
        nation_code: nation_code ?? this.selectedNation.code,
        product__id,
        quantity,
        user__id: this.currentUser._id,
      })
      console.log(`postCart() res`, res)

      this.set_currentUserAndSubfields(res)

    } catch (e) {
      console.error(`e`, e)
      apiError = e
    }

    this.view.hideLoading("dataService_postCart_loading")

    if (apiError) {
      if (throwToCaller) {
        throw apiError
      } else {
        // TODO error alert
      }
    }

  }

  // * Currency

  // async selectCurrency(
  //   currency_code?: string,
  //   noApi?: boolean,
  //   noGlobalSettings_event?: boolean,
  // ) {
  async selectCurrency(opts?: selectCurrency_opts) {
    let {
      currency_code,
      noApi,
      noGlobalSettings_event,
    } = opts || {}

    if (!currency_code) { // CONCETTUALMENTE SBAGLIATO
      currency_code = "EUR"
    }
    this.selectedCurrency = getListItem({
      key: "code",
      list: this.currencies,
      value: currency_code,
    });
    console.log(`selectedCurrency`, this.selectedCurrency)
    View.updateView.next(null)
    if (!noGlobalSettings_event) {
      DataService.globalSettings.next({
        action: "currency-changed",
      });
    }
    if (!noApi) {
      await this.getUser({
        currency: currency_code,
      });
    }
  }

  // * dialog

  promptForAppReload(lang: any) {
    const data: DialogConfirmData = {
      // icon: icon,
      iconMat: {
        color: "warn",
        name: "warning",
      },
      msg: lang.APP_INIT.reload_app,
      buttons: [
        // {
        //   lbl: "Annulla",
        // },
        {
          // cdkFocusInitial: true,
          color: "primary",
          lbl: lang.GLOBAL_MISC.ok,
          matDialogClose: "confirmed",
        },
      ]
    }

    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: data,
      disableClose: true,
      height: '300px',
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      localStorage.clear();
      console.log("dialogRef.afterClosed() result", result)
      location.reload();
    })
    // ? unsubscribe?
  }

  // * general_parameter


  // * language

  getDefaultLanguage() {
    return (
      Utility.parseLanguageCode(
        localStorage["appLanguage"] ||
        // fallback to browser language
        this.translate.getBrowserLang() || navigator.language
      )
    )
  }

  /**
   * see also: appComponent.setLang()
   * @param langKey
   * @param noApi
   */
  async selectLanguage(opts?: selectLanguage_opts) {
    let {
      langKey,
      noApi,
      noGlobalSettings_event,
    } = opts || {}
    if (!langKey) { // CONCETTUALMENTE SBAGLIATO
      langKey = this.getDefaultLanguage()
    }
    this.currentLang = langKey;
    this.currentLangName = this.languages[this.currentLang];

    console.log(`currentLang`, this.currentLang)
    View.updateView.next(null)

    if (!noGlobalSettings_event) {
      DataService.globalSettings.next({
        action: "language-changed",
      })
    }

    // I set this just because it's used in app.module
    localStorage.setItem("appLanguage", langKey)

    if (langKey !== Utility.parseLanguageCode(this.translate.currentLang)) {
      this.translate.use(langKey)
      // need to subscribe for the view update to be effective
      // View.updateView.next(null)
    }

    if (!noApi) {
      // if (!this.currentUser) {
      //   console.log(`selectLanguage() return: !currentUser`, )
      //   return
      // }
      await this.getUser(/* this.currentUser._id,  */{
        language: langKey,
      })
    }
  }

  // * listener

  /* listener_globalSettings() {
    this.listener.globalSettings = DataService.globalSettings$.subscribe((obj?: any) => {
      if (obj?.action) {
        switch (obj.action) {
          case "currency-changed":
            this.selectCurrency(this.selectedCurrency.code)
            break;
          case "language-changed":
            this.selectLanguage(this.currentLang)
            break;
          case "nation-changed":
            this.selectNation(this.selectedNation.code)
            break;

          default:
            break;
        }
      }
    })
  } */

  // * Nation

  async selectNation(
    nation_code?: string/*  = this.FILTER_KEY_ALL */,
    noApi?: boolean,
    noGlobalSettings_event?: boolean
  ) {
    if (!nation_code) { // CONCETTUALMENTE SBAGLIATO
      nation_code = this.FILTER_KEY_ALL;
    }
    this.selectedNation = getListItem({
      key: nation_code,
      list: this.nations
    });
    console.log(`selectedNation`, this.selectedNation);
    View.updateView.next(null);
    if (!noGlobalSettings_event) {
      DataService.globalSettings.next({
        action: "nation-changed",
      });
    }
    if (!noApi && nation_code !== this.FILTER_KEY_ALL) {
      await this.getUser({
        nation: nation_code
      });
    }
    if(this.selectedNation && this.selectedCurrency){
      for(let brand of this.brandsMap.values()){
        brand.$in_sales = false;
      }
      View.updateView.next(null);
      this.api.getPromotions(this.selectedCurrency?.code, this.selectedNation.code).then((res: any) => {
        if(res?.data){
          for(let prod of res.data){
            let brand = this.brandsMap.get(prod.idBrand);
            if(brand){
              brand.$in_sales = true;
            }
          }
          View.updateView.next(null);
        }
      }).catch((err) => {
        console.error('Impossibile recuperare le promozioni', err);
      });
    }
  }

  // * Product

  /*
    i parametri di questa funzione sto continuando a cambiarli
    per non stare a cambiare i posti in cui la chiamo in continuazione
    ho preferito passare un literal come parametro...
  */
  // /* apiOpts: getProduct_apiOpts,  */
  /* async getProduct(opts: getProduct_opts) {
  // async getProduct(brand_id: string, getQuantity?: boolean, noLoading?: boolean) {
    return new Promise(async (resolve, reject) => {
      const {
        brand_id,
        getQuantity,
        noLoading,
        noReject,
      } = opts || {}

      if (!noLoading) {
        this.view.showLoading(this.lang.DATA_SERVICE.product_loading)
      }

      try {
        const res: any = await this.api.getProduct({
          brand_id,
          currency_code: this.selectedCurrency!.code,
          getQuantity,
          nation_code: this.selectedNation!.code,
        })
        console.log(`res`, res)
        this.navParam = new Product(res.data)
        throw "eeeeeeeee"
        resolve(null)
      } catch (e) {
        console.error(`e`, e)
        // TODO error alert
        if (!noReject) {
          reject(null)
          // return
        }
      }

      if (!noLoading) {
        this.view.hideLoading()
      }
      resolve(null)
      console.log(`wui`, )
    })
  } */

  // * user

  /**
   * a more appropriate name would be "get_currentUser"
   */
  async getUser(/* user__id?: string,  */opts?: getUser_opts) {
    console.log('getUser')
    const {
      // appIsStarting,
      currency, // used in this.api.getUser
      language, // used in this.api.getUser
      nation, // used in this.api.getUser
      noLoading,
      throwToCaller,
      // withReturn,
    } = opts || {}

    if (!noLoading) {
      // another way is to generate a random id
      // ? but is it more convenient here or inside showLoading() ?
      await this.view.showLoadingAsync(this.translate.instant('DATA_SERVICE.saving_user_settings'), "dataService_getUser_loading")
    }

    if (!(this.currentUser?._id || localStorage["currentUser._id"])) {
      console.log(`currentUser._id not in memory nor in localStorage`, )
    }

    /* let res *//* : any */
    let apiError

    try {
      const res = await this.api.getUser(
        this.platform,
        // user__id,
        this.currentUser?._id || localStorage["currentUser._id"],
        {
          currency,
          language,
          nation,
        },
      )
      console.log(`getUser() res`, res)

      this.set_currentUserAndSubfields(res)

      // test
      // throw "test erorr"
    } catch (e) {
      console.error(`e`, e)
      apiError = e
    }

    if (!noLoading) {
      await this.view.hideLoadingAsync("dataService_getUser_loading");
      console.log('close get user');
    }

    if (apiError) {
      console.error(apiError)
      if (throwToCaller) {
        throw apiError
      } else {
        // TODO error alert
      }
    }

    // if (withReturn) {
      // return res
    // }
  }

  set_currentUserAndSubfields(value: User) {
    if (this.currentUser) {
      this.currentUser.setAllFields(value);
      // * subfields
      this.cart?.setAllFields(value.lastCart);

    } else {
      this.currentUser = new User(value)
      // * subfields
      this.cart = new Cart(value.lastCart)

    }



    View.updateView.next(null)
  }











}
