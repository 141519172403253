import { getListItem } from "../misc/util";
import { DataService } from "../services/data.service";
import { Product } from "./product";

type productsElemRemove_opts = {
  el__id: string
  elIndex?: number
}
type productsSet_opts = {
  flux: "create" | "update"
  value: Product[]
}

export class Cart {

  arrayProducts: Product[] = [];
  cart_number!: string;
  timestamp_create?: number;
  timestamp_update?: number;
  timestamp_validity?: number;
  total?: number;
  total_currency!: string; // currency.code
  total_with_fee?: number;
  total_converted_currency!: string; // currency.code
  total_with_fee_converted?: number; // currency.code
  fee: any;
  discount_value?: number;
  discount: any;

  $productsMap: Map<string, Product> = new Map();

  constructor(data: any) {
    this.setAllFields(data);
  }

  setAllFields(data: any) {
    if (data) {
      this.timestamp_create = data.timestamp_create;
      this.timestamp_update = data.timestamp_update;
      this.timestamp_validity = data.timestamp_validity;
      this.cart_number = data.cart_number;
      if(this.arrayProducts){
        this.productsSet({
          flux: "update",
          value: data.arrayProducts
        });
      }else{
        this.productsSet({
          flux: "create",
          value: data.arrayProducts
        });
      }
      this.total_currency = data.total_currency;
      this.total = data.total;
      this.total_with_fee = data.total_with_fee;
      this.total_with_fee_converted = data.total_with_fee_converted;
      this.total_converted_currency = data.total_converted_currency;
      this.fee = data.fee;
      this.discount = data.discount;
      this.discount_value = data.discount_value;
      console.log('init cart', this.discount_value)
    }
  }

  productsSet(opts: productsSet_opts) {
    const {
      flux,
      value,
    } = opts;

    if (!value) {
      this.arrayProducts = [];
      return;
    }

    switch (flux) {
      case "create":
        this.arrayProducts = [];
        value.forEach((el: Product) => {
          this.productsElemAdd(el)
        });
        break;

      case "update":
        value.forEach((el: Product) => {
          const found: Product = getListItem({
            key: el._id,
            list: this.$productsMap
          });
          if (found) {
            found.setAllFields(el);
          } else {
            this.productsElemAdd(el);
          }
        });

        const valuesWereRemoved = value.length < this.arrayProducts.length;
        if (!valuesWereRemoved) {
          break;
        }

        let elRemoved: boolean | null;

        this.$productsMap.forEach((el) => {
          elRemoved = null;
          elRemoved = !( value.some((valueEl: Product) => valueEl._id === el._id) );

          if (elRemoved) {
            this.productsElemRemove({
              el__id: el._id
            });
          }
        });
        break;

      default:
        break;
    }
  }

  productsElemAdd(el: Product) {
    this.$productsMap.set(el._id, new Product(el));
    this.arrayProducts.push(this.$productsMap.get(el._id)!);

    DataService.globalSettings.next({
      action: "cart_products-changed",
    });
  }

  productsElemRemove(opts: productsElemRemove_opts) {
    const {
      el__id,
    } = opts;
    let {
      elIndex,
    } = opts;

    if (!elIndex && elIndex !== 0) {
      elIndex = this.arrayProducts.findIndex(
        (el) => el._id === el__id
      );
    }
    if (elIndex !== -1) {
      this.arrayProducts.splice(elIndex!, 1);
    }

    this.$productsMap.delete(el__id);

    DataService.globalSettings.next({
      action: "cart_products-changed",
    });
  }

  getProduct(id: string): Product | undefined{
    return this.arrayProducts.find((elem: Product) => elem._id === id);
  }
}
























