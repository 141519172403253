import {
  /* Injectable,  */Pipe,
  PipeTransform
} from '@angular/core';
import {Brand} from "../models/brand";

@Pipe({
  name: 'sortPromo'
})
// @Injectable()
export class SortPromoPipe implements PipeTransform {
  transform(list: Brand[]): any[] {

    return list.sort((a, b) => {
      return (a.$in_sales === b.$in_sales)? 0 : a.$in_sales? -1 : 1;

    });
  }
}
