import { NgModule } from '@angular/core';

import { FilterPipe } from './filter.pipe';
import { SortAlphabeticallyPipe } from './sortAlphabetically.pipe';
import {SortPromoPipe} from "./sortPromo.pipe";

@NgModule({
  declarations: [
    FilterPipe,
    SortAlphabeticallyPipe,
    SortPromoPipe,
  ],
  exports: [
    FilterPipe,
    SortAlphabeticallyPipe,
    SortPromoPipe,
  ],
  imports: [],
})

export class PipesModule {}
