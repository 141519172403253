<div class="hr mt-t1"></div>

<div class="isle isle--cart-recap mt-t1">
  <div class="isle__title sub-section-lbl sub-section-lbl--title">
    {{ 'ORDER.order_summary' | translate }}
  </div>


  <div class="isle__card">

    <ng-container *ngIf="order?.cart">
      <div class="recap-lines">

        <div class="recap-line">
          <div class="rl-part rl-lbl">
            {{ 'ORDER.data' | translate }}
          </div>
          <div class="rl-part rl-cost">
            {{order?.creation_date | date:'dd/MM/yyyy, HH:mm'}}
          </div>
        </div>

        <div class="recap-line">
          <div class="rl-part rl-lbl">
            {{ 'ORDER.metodo_pagamento' | translate }}
          </div>
          <div class="rl-part rl-cost">
            <ng-container [ngSwitch]="order?.payment_type">
              <ng-container *ngSwitchCase="1">
                {{'CART.valute_digitali' | translate}}
              </ng-container>
              <ng-container *ngSwitchCase="2">
                {{'CART.carte_credito' | translate}}
              </ng-container>
              <ng-container *ngSwitchCase="3">
                {{'CART.lighting_network' | translate}}
              </ng-container>
              <ng-container *ngSwitchDefault>
                --
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div *ngFor="let item of order?.cart?.arrayProducts"
             class="recap-line">
          <div class="rl-part rl-lbl">
            {{ item.quantity_cart }} x {{ item.name }}
          </div>
          <div class="rl-part rl-cost">
            {{ item.price_converted_cart }}
            {{ data.currenciesMap.get(item.currency_converted)?.symbol }}
          </div>
        </div>

        <div class="recap-line">
          <div class="rl-part rl-lbl">
            {{ 'CART.costi_servizio' | translate }}
          </div>
          <div class="rl-part rl-cost">
            {{payment_type ? order?.cart?.fee[payment_type]?.fee : '--'}} {{ data.currenciesMap.get(order?.cart?.total_currency || '')?.symbol }}
          </div>
        </div>


      </div>

      <hr class="hr">

      <div class="recap-line recap-line--total">
        <div class="rl-part rl-lbl">
          {{ 'ORDER.total' | translate }}
        </div>
        <div class="rl-part rl-cost">
          {{ payment_type ? order?.cart?.fee[payment_type].total_with_fee : order?.cart?.total }} {{ data.currenciesMap.get(order?.cart?.total_currency || '')?.symbol }}
        </div>
      </div>

    </ng-container>
  </div>

</div>
