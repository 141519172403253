import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TopbarComponent } from './topbar/topbar.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogConfirmRemoveCartItemComponent } from './dialog-confirm-remove-cart-item/dialog-confirm-remove-cart-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/__pipes.module';
import { BottombarComponent } from './bottombar/bottombar.component';
import { MatSelectModule } from '@angular/material/select';
import { LazyImgComponent } from './lazy-img/lazy-img.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DialogLoadingComponent } from './dialog-loading/dialog-loading.component';
import { SelectNationComponent } from './select-nation/select-nation.component';
import { CountrySettingsComponent } from './country-settings/country-settings.component';
import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import {
  MatGridListModule
} from "@angular/material/grid-list";
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import {MatChipsModule} from "@angular/material/chips";
import { EmailOrderComponent } from './email-order/email-order.component';

@NgModule({
  declarations: [
    // component
    BottombarComponent,
    DialogConfirmComponent,
    DialogConfirmRemoveCartItemComponent,
    LazyImgComponent,
    TopbarComponent,
    DialogLoadingComponent,
    SelectNationComponent,
    CountrySettingsComponent,
    CartSummaryComponent,
    ComingSoonComponent,
    NavigationBarComponent,
    EmailOrderComponent,

    // popover

  ],
  // entryComponents: [
  //   // popover

  // ],
  exports: [
    // component
    // (when the component needs to be called in html)
    // eg. <app-topbar> in app.component.html
    BottombarComponent,
    // DialogConfirmComponent,
    // DialogConfirmRemoveCartItemComponent,
    LazyImgComponent,
    TopbarComponent,
    SelectNationComponent,
    CartSummaryComponent,
    NavigationBarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule,
    // VirtualScrollerModule,

    // @angular/material
    MatAutocompleteModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatGridListModule,
    MatChipsModule,

  ],
})

export class ComponentsModule {}
