<div class="component">
  <div class="component-inner">

    <div class="grid-container">
      <div class="grid-item">
        <div>
          <img class="mb-2 img-footer-logo" src="assets/img/logo-full.svg" alt="">
          <p>
            © {{ 'BOTTOMBAR.rights_reserved' | translate }}
          </p>
          <p>
            {{'BOTTOMBAR.version' | translate}}: {{appVersion}}<br>

            <ng-container *ngIf="platform === 2">
              Powered by Wi-tek<br>
              VAT: IT03256570049<br>
            </ng-container>

            <br>Made with 💚 for e-Gift fan<br>

          </p>
          <div class="external-logos">
            <a class="ext-logo" href="{{ data.general_parameter?.urls?.facebook }}" target="_blank">
              <img class="ext-logo__pic" src="assets/img/logos-external/facebook_443aa7.svg">
            </a>
            <a class="ext-logo" href="{{ data.general_parameter?.urls?.instagram }}" target="_blank">
              <img class="ext-logo__pic" src="assets/img/logos-external/instagram_443aa7.svg">
            </a>
            <a class="ext-logo" href="{{ data.general_parameter?.urls?.twitter }}" target="_blank">
              <img class="ext-logo__pic" src="assets/img/logos-external/twitter_443aa7.svg">
            </a>
            <a class="ext-logo" href="{{ data.general_parameter?.urls?.youtube }}" target="_blank">
              <img class="ext-logo__pic" src="assets/img/logos-external/youtube_443aa7.svg">
            </a>
          </div>
        </div>
      </div>

      <div class="grid-item">
        <h5>{{'BOTTOMBAR.risorse' | translate}}</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1">
            <a class="link-secondary text-decoration-none" target="_blank" [href]="data?.general_parameter?.urls?.how_it_works">
            {{'BOTTOMBAR.come_funziona' | translate}}
            </a>
          </li>
          <li class="mb-1">
            <a class="link-secondary text-decoration-none" target="_blank" [href]="data?.general_parameter?.urls?.terms">
              {{'BOTTOMBAR.termini' | translate}}
            </a>
          </li>
          <li class="mb-1">
            <a class="link-secondary text-decoration-none" target="_blank" [href]="data?.general_parameter?.urls?.privacy">
              {{'BOTTOMBAR.privacy' | translate}}
            </a>
          </li>
          <li class="mb-1">
            <a class="link-secondary text-decoration-none" [href]="data?.general_parameter?.urls?.contact_us">
              {{'BOTTOMBAR.contattaci' | translate}}
            </a>
          </li>
        </ul>
      </div>

      <div class="grid-item">
        <h5>{{'BOTTOMBAR.partnership' | translate}}</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1">
            <a class="link-secondary text-decoration-none" [href]="'/referral-login'">
              {{'BOTTOMBAR.referral_program' | translate}}
            </a>
          </li>
        </ul>
      </div>

      <div class="grid-item">
        <h5>{{'BOTTOMBAR.lingue' | translate}}</h5>
        <ul class="list-unstyled text-small">
          <li class="mb-1"><a class="link-secondary text-decoration-none" (click)="changeLanguage('en')">English</a></li>
          <li class="mb-1"><a class="link-secondary text-decoration-none" (click)="changeLanguage('it')">Italiano</a></li>
        </ul>
      </div>

      <div class="grid-item">
        <h5>{{'BOTTOMBAR.payments' | translate}}</h5>
        <img src="assets/img/referral/crypto-footer.png">
      </div>
    </div>

  </div>
</div>


