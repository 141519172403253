import { Cart } from "./cart"

export class User {

  _id!: string;
  currency?: string; // Currency.code
  language?: string;
  lastCart?: Cart;
  timestamp_last_update?: number;
  nation?: string; // Nation.code

  constructor(data: any) {
    this.setAllFields(data);
  }

  setAllFields(data: any) {
    if (data) {
      this._id = data._id;
      this.currency = data.currency;
      this.language = data.language;
      if(this.lastCart){
        this.lastCart.setAllFields(data.lastCart);
      }else{
        this.lastCart = new Cart(data.lastCart);
      }
      this.timestamp_last_update = data.timestamp_last_update;
      this.nation = data.nation;
    }
  }

}











