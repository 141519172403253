import { Nation } from "./nation"

// E' per le traduzioni delle descrizioni e dei tabs del brand
type Translations = {
  [key: string]: Translations_value
}
type Translations_value = {
  description: string
  tabs: Translations_value_Tabs
}
type Translations_value_Tabs = {
  [key: number]: {
    title: string
    description: string
  }
}


export class Brand {
  _id!: string;
  brand!: string;
  category: string[] = [];
  countries: any = {};
  countries_group: any = {};

  image?: string;
  isAvaible?: boolean;

  $searchbarNation?: Nation | null;

  private _translations?: Translations;

  $translations_index0Key?: string;
  $translations_index0Value?: Translations_value;

  meta!: any[];

  $in_sales: boolean = false;

  constructor(data: any) {
    this.setAllFields(data);
  }

  setAllFields(data: any) {
    if (data) {
      this._id = data._id;
      this.brand = data.brand;
      this.category = data.category;
      this.countries = data.countries;
      this.countries_group = data.countries_group;
      this.image = data.image;
      this.isAvaible = data.isAvaible;
      this.translations = data.translations;
    }
  }

  public get translations(): Translations | undefined {
    return this._translations;
  }
  public set translations(value: Translations | undefined) {
    this._translations = value;
    if (!value) {
      value = {};
    }
    this.$translations_index0Key = Object.keys(value)[0];
    this.$translations_index0Value = Object.values(value)[0];
  }

}
