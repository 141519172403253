<div class="modal-view">

  <div mat-dialog-title
       class="mat-dialog-title">

    <button mat-icon-button
            class="close-btn"
            mat-dialog-close
    >
      <mat-icon class="mat-icon">
        close
      </mat-icon>
    </button>

  </div>

  <div mat-dialog-content class="mat-dialog-content">

    <h3>
      <i class="fas fa-envelope"></i>
      {{ "EMAIL_ORDER.email_richiesta" | translate }}
    </h3>
    <p>
      {{ "EMAIL_ORDER.email_info" | translate }}
    </p>
    <mat-form-field class="mat-form-field" appearance="outline">
      <mat-label>
        {{ "EMAIL_ORDER.indirizzo_email" | translate }}
      </mat-label>
      <input matInput
             placeholder="{{ 'EMAIL_ORDER.placeholder_email' | translate }}"
             [formControl]="formControl.email" type="email"
             required>
      <mat-error *ngIf="formControl.email.invalid">
        {{ form_getErrorMessage('email') }}
      </mat-error>
    </mat-form-field>
  </div>

  <div mat-dialog-actions
       class="mat-dialog-actions">

    <button mat-raised-button color="primary" [disabled]="formControl.email.invalid" (click)="createOrder()">
      {{"EMAIL_ORDER.conferma" | translate}}
    </button>

  </div>

</div>
