import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import {DataService} from "../../services/data.service";
import {Order} from "../../models/order";

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartSummaryComponent implements OnInit {

  @Input() order?: Order;
  @Input() payment_type?: number;


  constructor(
    public data: DataService
  ) { }

  ngOnInit(): void {
  }

}
