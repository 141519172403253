import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// * firebase

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase/* , ref, child, get */ } from "firebase/database";
import { DataService } from './services/data.service';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCzPIGpaS_vOIsi-ylomF7MXy3W4fwYl3E",
  authDomain: "gift-card-eecde.firebaseapp.com",
  databaseURL: "https://gift-card-eecde-default-rtdb.firebaseio.com",
  projectId: "gift-card-eecde",
  storageBucket: "gift-card-eecde.appspot.com",
  messagingSenderId: "639817381415",
  appId: "1:639817381415:web:3bbb34bc0dc70499194002",
  measurementId: "G-HVP5CP5V4R"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseRealtimeDb = getDatabase(firebaseApp);


import { Utility } from './models/utility';
import { ViewService } from 'src/app/services/view.service';
import { ApiService } from './services/api.service';
import {Gtag} from "angular-gtag";
import {environment} from "../environments/environment";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {NavigationEnd, Router} from "@angular/router";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {

  title = 'GiftCard';

  // * listener

  listener: any = {
    view: null,
  }

  inScroll: boolean = false;
  show_top_bar?: boolean;
  show_bottom_bar?: boolean;

  constructor(
    private translate: TranslateService,
    private api: ApiService,
    private data: DataService,
    private cd: ChangeDetectorRef,
    private view: ViewService,
    private gtag: Gtag,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router
  ) {
    this.initApp()
    this.matIconRegistry.addSvgIcon(
      `help-personal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/img/help.svg")
    );

    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){

        if(
          true
          // event.url === '/coming-soon' ||
          // event.url === '/out-of-service'
        ){
          this.show_bottom_bar = false;
          this.show_top_bar = false;
        }else{
          this.show_bottom_bar = true;
          this.show_top_bar = true;
        }
        ViewService.updateView.next(null);
      }
    });
  }

  async initApp() {
    this.translate.use("en");

    // ? move in dataService constructor?
    await this.data.initDataService();

    try{
      this.gtag.event(environment.g_analytics.event_pageLoad);
    }catch(err){
      console.error('Errore gtag', err);
    }

    /** Settaggio lingua app come lingua browser **/
    // await this.setLang() // works!
  }



  // * language

  /**
   * * unused (as of 11 oct '21)
   * copied from balinApp (not balinApp_mobile)
   * @param args
   * @returns
   */
  setLang(args: {
    langCode?: string
  } = {}) {
    return new Promise((resolve, reject) => {
      // console.log(`navigator`, navigator)
      // console.log(`navigator.language`, navigator.language)
      // console.log(`navigator.language regex`, /^en\b/.test(navigator.language))
      let { langCode } = args;
      console.log(`LINGUA RICHIESTA --> ${langCode}`);

      if (!langCode) {
        // const navigatorlanguage: stringß = "de"
        // console.log('navigatorlanguage: ', navigator.language);
        langCode = Utility.parseLanguageCode(
          localStorage["appLanguage"] ||
          // fallback to browser language
          this.translate.getBrowserLang() || navigator.language
        );
        switch (langCode) {
          // case "de":
          //   // langCode = "de";
          //   break;
          case "it":
            langCode = "it";
            break;
          default:
            langCode = "en"
        }
        // console.log(`!langCode`, langCode)
        this.translate.use(langCode).subscribe(() => {
          console.log(`LINGUA CARICATA 1 --> ${this.translate.currentLang}`);
          this.data.currentLang = this.translate.currentLang
          return resolve(null);
        })
      } else {
        // const found = DataService.generalConfig.languages.find(
        //   obj => obj.code === langCode
        // );
        // console.log(`found`, found)
        this.translate.use(/* found?.code ??  */"en").subscribe(() => {
        // this.translate.use("de").subscribe(() => {
          console.log(`LINGUA CARICATA 2 --> ${this.translate.currentLang}`);
          this.data.currentLang = this.translate.currentLang
          return resolve(null);
        })
      }
    });
  }

  // * listener

  /* listenerView() {
    this.listener.view = ViewService.updateView$.subscribe((obj?: any) => {
      console.log(`View.updateView$`, )
      this.view.pipeChanged++;
      this.cd.detectChanges();
      this.cd.markForCheck();
      setTimeout(() => {
        this.view.pipeChanged++;
        this.cd.detectChanges();
        this.cd.markForCheck();
      }, 250);
    });
  }

  unsubscribeAllListeners() {
    for (const key in this.listener) {
      this.listener[key].unsubscribe()
    }
  } */

  onScroll(event: any){
    if(event.target.scrollTop > 80){
      this.inScroll = true;
    }else{
      this.inScroll = false;
    }
  }

}
